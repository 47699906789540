import React from "react"
import {graphql} from "gatsby"
import MainLayout from "Layout/layout"
import IntroText from "Layout/IntroText/IntroText"
import FlexibleContent from "Layout/FlexibleContent/FlexibleContent"

const TechniquesJournal = ({location, data}) => {

  const {page, optionsPage} = data
  const gI = optionsPage.edges[0].node.global_data.generic_images

  return (
    <MainLayout location={location}>
      <IntroText intro={page.acf.intro_text} headline={page.title} genericImages={gI} classes="py-5" />
      {page.acf.content_page && page.acf.content_page.length && (
        <div className="section-flexible-content section pb-5 container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-6 text-lg offset-md-4 offset-lg-6 mb-5">
                <FlexibleContent content={page.acf.content_page} location={location} pageName="PageTemplate" />
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  )
}

export const query = graphql`
  query techniquesJournalPageQuery {
    page: wordpressPage(wordpress_id: {eq: 998}) {
      title
      id
      acf {
        intro_text {
          paragraphs {
            paragraph
          }
        }
        content_page {
          __typename
          ...BasicSliderSectionPageFragment
          ...TextSectionPageFragment
        }
      }
    }
    optionsPage: allWordpressAcfOptions {
      edges {
        node {
          global_data {
            generic_images {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TechniquesJournal
